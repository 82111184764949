import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
// import Router from './router'
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {
    })
};
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/billsAdmin',
        name: 'billsAdmin',
        component: () => import('../views/billsAdmin.vue'),
    },
    {
        path: '/device',
        name: 'device',
        component: () => import('../views/Device.vue'),
    },
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path !== "/") {
        if (localStorage.getItem("phone") === null || localStorage.getItem("phone") === "") {
            next("/")
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
