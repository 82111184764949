/*
*  进行网络请求的工具类
* */
// 导入 axios  进行网络请求
import axios from 'axios'
// 导入 Element UI的 Message 模块  是为了展示提示消息
import {Message} from 'element-ui';
import Cookies from "js-cookie";
// 创建 axios 对象
const instance = axios.create({
   // 请求的跟路径
   baseURL: '/api',
   // 请求的超时时间  3秒
   timeout: 3000,
   withCredentials:true
});
//配置前置请求拦截器
instance.interceptors.request.use(function (config) {
   config.headers['token']=Cookies.get("token");//每次请求都从Cooike里面取出token
   return config
},function (error){
   return Promise.reject("请求被拦截");
})


// 设置 过滤器, 响应数据的过滤器
instance.interceptors.response.use(response=>{
   // 此时执行 HTTP响应码 一定是 200
   // 获取 业务数据
   let data = response.data;
   if(data.code != 200){
      // 将异常信息进行展示
      Message.error(data.msg);
      return Promise.reject(data.msg);
   }
   return data;
},reason=>{
   // 将错误信息展示
   Message.error(reason);
   return;
})
export default function request(requestConfig) {
   // 请求就这样发
   return instance(requestConfig)
}