<template>
  <div class="myDiv">
    <h6>内部使用，请勿外发</h6>
    <hr/>
    <div>
      <el-form :model="getLoginTokenDTO" :rules="rules" ref="form">
        <el-form-item label="手机号" label-width="80px" prop="username">
          <el-input v-model="getLoginTokenDTO.phone" placeholder="请输入手机号" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="平台" label-width="80px" prop="username">
          <el-select v-model="getLoginTokenDTO.type" clearable placeholder="请选择平台"
                     style="width: 300px">
            <el-option
                v-for="item in platform"
                :key="item.key"
                :label="item.type"
                :value="item.url">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="warning" @click="reset">重置</el-button>
        <el-button type="success" @click="login">进入</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {doLogin, getCaptcha} from "@/api/login";
import Cookies from 'js-cookie'
import {Message} from "element-ui";

export default {
  name: 'login',
  data() {
    return {
      disable: true,
      getLoginTokenDTO: {
        phone: "",
        type: ""
      },
      rules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
      },
      platform: [{
        key: 1,
        type: "奥飞平台",
        url: "https://gfgj.aofeine.com/rootapi/"
      }, {
        key: 2,
        type: "通用租户",
        url: "https://public.aofeine.com/rootapi/"
      }],
    }
  },
  created() {
    // this.getPlatform();
    this.getLoginTokenDTO.type = "https://gfgj.aofeine.com/rootapi/"
  },
  methods: {
    getPlatform() {
      axios.get("/api/auth/getType", {}).then(res => {
        if (res.data.code !== 200) {
          this.platform = res.data.data
          Message.error("请求数据失败");
          return
        }
        this.platform = res.data.data
      }).catch(error => {
        console.log(error)
        Message.error("请求数据失败");
      })
    },
    getCode() {
      getCaptcha().then(res => {
        this.codeUrl = res.data.code
        this.form.codeId = res.data.codeId
      })
    },
    reset() {
      this.form = {}
    },
    login() {
      if (this.getLoginTokenDTO.phone === null || this.getLoginTokenDTO.phone === "") {
        Message.error("请输入手机号")
        return
      }
      if (this.getLoginTokenDTO.type === null || this.getLoginTokenDTO.type === "") {
        Message.error("请选择平台");
        return;
      }
      axios.post("/api/auth/getLoginToken", this.getLoginTokenDTO).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          localStorage.setItem("phone", this.getLoginTokenDTO.phone)
          localStorage.setItem("url", this.getLoginTokenDTO.type)
          Message.success("登录成功")
          this.$router.push("/device")
        } else {
          this.$message.error("请检查手机号，登录失败")
        }
      }).catch(error => {
        Message.error("手机号验证失败");
      })
    }
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.myDiv {
  height: 100%;
  width: 100%;
}

</style>
