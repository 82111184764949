import request from "@/utils/request";

let modelName = "/auth"

/**
 * 用户登陆
 * @param params
 */
export function doLogin(params) {
    return request({
        url: `${modelName}/getLoginToken`,
        method: "post",
        body: JSON.stringify(params)
    })
}

/**
 * 得到验证码
 * @param params
 */
export function getCaptcha() {
    return request({
        url: `/captcha/getCaptcha`,
        method: "get"
    })
}